<script>
import { computed, ref, watch } from '@vue/composition-api'
import { isNull } from '@core/utils/is'

export default {
  name: 'DateRangePickers',
  props: {
    multiple: Boolean,
    value: {
      type: [String, Array],
      default: () => null,
    },
    range: Boolean,
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rangeSeparator: {
      type: String,
      default: '-',
    },
    clearable: Boolean,
  },
  setup(props, { emit }) {
    const pickers = ref()
    const dateValue = computed({
      get() {
        if (pickers.value) return pickers.value

        return props.value
      },
      set() {},
    })

    const isRange = computed(() => props.range)
    const isMultiple = computed(() => props.multiple)
    const show = ref(false)

    const text = computed({
      get() {
        if (props.range && (!dateValue.value[0] && !dateValue.value[1])) {
          return []
        }

        return props.multiple || props.range ? dateValue.value.join(`   ${props.rangeSeparator}   `) : dateValue.value
      },
      set(val) {
        if (isNull(val) && (isMultiple.value || isRange.value)) return emit('input', [])
        if (isNull(val)) return emit('input', undefined)
      },
    })

    if ((isRange.value || isMultiple.value) && !Array.isArray(props.value)) throw new Error('期望是Array类型')
    if (!isRange.value && !isMultiple.value && Array.isArray(props.value)) throw new Error('期望是String类型')

    function close(val) {
      if (props.range) val.sort((a, b) => new Date(a) - new Date(b))

      emit('input', val)
      emit('change')
      show.value = false
    }

    watch(() => pickers.value, val => {
      emit('input', val)
    })

    watch(() => props.value, val => { pickers.value = val })

    return {
      show,
      isMultiple,
      text,
      isRange,
      dateValue,
      pickers,
      close,
    }
  },
}
</script>

<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    min-width="auto"
    nudge-top="-8"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-if="!isMultiple"
        v-model="text"
        :clearable="clearable"
        :label="label"
        :placeholder="placeholder"
        :style="isRange ? { width: '250px', flexShrink: 0 } : {}"
        dense
        hide-details
        outlined
        readonly
        single-line
        append-icon="mdi-calendar-week-outline"
        class="form-datepicker"
        v-bind="attrs"
        v-on="on"
      />
      <v-combobox
        v-else
        v-model="dateValue"
        :clearable="clearable"
        chips
        label="Multiple picker in menu"
        multiple
        prepend-icon="mdi-calendar"
        readonly
        small-chips
        single-line
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="pickers"
      :multiple="multiple"
      :range="range"
      v-bind="$attrs"

      @change="close"
    />
  </v-menu>
</template>
<style lang="scss" scoped>
.form-datepicker{
    ::v-deep .v-input__slot{
      width: 250px;
      max-width: 250px!important;
      .v-input__append-inner .v-input__icon--append{
        display: none!important;
      }
    }
  }
</style>
